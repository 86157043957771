/* eslint-disable react/no-array-index-key */
import Img from "gatsby-image";
import PropTypes from "prop-types";
import React from "react";
import Par from "../components/Par";

const textProp = PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(String)]);

const listProp = PropTypes.arrayOf(PropTypes.string);

const Paragraphs = ({ data }) => {
	if (Array.isArray(data)) {
		return (
			<>
				{data.map((text, i) => (
					<Par key={i} text={text} />
				))}
			</>
		);
	}
	return <Par text={data} />;
};

Paragraphs.propTypes = {
	data: textProp.isRequired,
};

const List = ({ data }) => (
	<ul className="mb-6 mt-3 leading-snug arrow">
		{data.map((item, i) => (
			<li key={i} className="mb-2 relative">
				{item}
			</li>
		))}
	</ul>
);

List.propTypes = {
	data: listProp.isRequired,
};

const ProfilePage = ({
	title,
	quote,
	name,
	img,
	specialty,
	education,
	description,
	honours,
	background,
	appointments,
	memberships,
	personal,
	publications,
}) => {
	return (
		<div>
			<h1 className="h1">{title}</h1>
			<div style={{ alignItems: "start" }} className="grid gap-8 sm:grid-cols-profile">
				<div className="bg-grey-lighter px-4 pt-4 shadow-md sm:max-w-profile-panel">
					<div style={{ maxWidth: "200px" }} className="mx-auto mb-4">
						<Img alt={`${name}, Gastroenterologist`} fixed={img} />
					</div>
					<blockquote className="quote text-primary-dark font-serif italic">{quote}</blockquote>
					<div className="ml-2">
						<h2 className="h2">
							<span role="img" aria-label="graduation-hat">
								📜
							</span>{" "}
							Specialty
						</h2>
						<Paragraphs data={specialty} />
						<h2 className="h2">
							<span role="img" aria-label="graduation-hat">
								🎓
							</span>{" "}
							Education
						</h2>
						<List data={education} />
					</div>
				</div>
				<div>
					<Paragraphs data={description} />
					<h2 className="h2 uppercase">Honours and Awards</h2>
					<List data={honours} />
					<h2 className="h2 uppercase">Background</h2>
					<Paragraphs data={background} />
					<h2 className="h2 uppercase">Professional Medical Memberships/committees</h2>
					<List data={memberships} />
					<h2 className="h2 uppercase">Academic Appointments</h2>
					<List data={appointments} />
					<h2 className="h2 uppercase">Personal</h2>
					<Paragraphs data={personal} />
					<h2 className="h2 uppercase">Publications</h2>
					<ol className="num mb-6 mt-3 leading-snug">
						{publications.map(({ publicationTitle, authors }, i) => (
							<li key={i} className="mb-3 relative">
								<b>{publicationTitle}</b>
								<br />
								{authors}
							</li>
						))}
					</ol>
				</div>
			</div>
		</div>
	);
};

ProfilePage.propTypes = {
	title: PropTypes.string.isRequired,
	quote: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	img: PropTypes.object.isRequired,
	specialty: textProp.isRequired,
	education: textProp.isRequired,
	description: textProp.isRequired,
	background: textProp.isRequired,
	appointments: listProp.isRequired,
	memberships: listProp.isRequired,
	personal: textProp.isRequired,
	publications: PropTypes.arrayOf(
		PropTypes.shape({
			publicationTitle: PropTypes.string.isRequired,
			authors: PropTypes.string.isRequired,
		})
	).isRequired,
};

export default ProfilePage;
